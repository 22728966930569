import { CssBaseline, ThemeProvider } from "@mui/material";
import { Suspense, lazy } from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import { Routes } from "./routes";
const MessageBox = lazy(() => import("./components/MessageBox"));

import { IdaasProvider } from "@entrustcorp/idaas-auth-react";
import Layout from "./components/Layout/Layout";
import { useAuth } from "./hooks/useAuth";
const { VITE_IDAAS_CLIENT_ID, VITE_IDAAS_ISSUER_URL } = import.meta.env;

export default function App() {
  const { loadTheme } = useAuth();

  return (
    <IdaasProvider issuerUrl={VITE_IDAAS_ISSUER_URL} clientId={VITE_IDAAS_CLIENT_ID} storageType="localstorage">
      <ErrorBoundary>
        <Suspense fallback="loading...">
          <ThemeProvider theme={loadTheme()}>
            <CssBaseline enableColorScheme />
            <Layout>
              <Routes />
              <MessageBox />
            </Layout>
          </ThemeProvider>
        </Suspense>
      </ErrorBoundary>
    </IdaasProvider>
  );
}
