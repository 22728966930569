import { type IdaasContextInterface, useIdaas } from "@entrustcorp/idaas-auth-react";
import { useRef } from "react";
import { useSnapshot } from "valtio";
import { type UserProviderStore, userStore } from "../stores/UserStore";

const LOGIN_URL = "/oauth/login/idaas";
const LOGOUT_URL = "/oauth/logout";

/**
 * The user state returned from the {@link useAuth} hook.
 */
export type UserState = {
  loginUrl: string;
  logoutUrl: string;
  idaas: IdaasContextInterface;
} & UserProviderStore;

export type UseAuth = () => UserState;

export const useAuth: UseAuth = () => {
  const userRef = useRef(userStore).current;
  const userSnapshot = useSnapshot<UserProviderStore>(userRef) as UserProviderStore;
  const idaas = useIdaas();

  return {
    ...userSnapshot,
    loginUrl: LOGIN_URL,
    logoutUrl: LOGOUT_URL,
    idaas,
  };
};
