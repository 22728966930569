import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { type MouseEvent, useState } from "react";
import { useLocation } from "wouter";
import { useAuth } from "../hooks/useAuth";
import { Paths } from "../paths";

export const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout, user, idaas, isLoggedIn } = useAuth();
  const [location, setLocation] = useLocation();

  const loggedIn = isLoggedIn();

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onProfileClicked = () => {
    handleClose();
    setLocation(Paths.profile);
  };

  const onLogoutClicked = async () => {
    if (idaas.isAuthenticated) {
      logout();
      idaas.logout({ redirectUri: window.location.origin });
    } else {
      handleClose();
      logout();
    }
  };

  const openMenu = Boolean(anchorEl);

  return (
    <Stack direction="row" alignItems="center">
      {loggedIn && <Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>}

      {loggedIn ? (
        <IconButton size="large" onClick={handleOpen} color="inherit">
          <AccountCircle />
        </IconButton>
      ) : null}

      {openMenu && (
        <Menu anchorEl={anchorEl} open onClose={handleClose}>
          {location !== Paths.profile && <MenuItem onClick={onProfileClicked}>Profile</MenuItem>}
          {/* LOGOUT */}
          <MenuItem onClick={onLogoutClicked}>Sign Out</MenuItem>
        </Menu>
      )}
    </Stack>
  );
};

export default ProfileMenu;
