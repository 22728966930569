export type ThemeId = "bank_of_entrust" | "carrefour_banque" | "entrust_university" | "well_fargo";

interface CardType {
  name: string;
  description: string;
  image: string;
}

export interface Theme {
  id: ThemeId;
  name: string;
  primary: string;
  secondary: string;
  logo: string;
  checking: CardType;
  savings: CardType;
}

const defaultChecking: CardType = {
  name: "Checking",
  description: "Debit Card",
  image: "/red_credit_card.png",
};

const defaultSavings: CardType = {
  name: "Savings",
  description: "ATM Card",
  image: "/blue_credit_card.png",
};

export const themes: { [name: string]: Theme } = {
  bankOfEntrust: {
    id: "bank_of_entrust",
    name: "Bank of Entrust",
    primary: "#723988",
    secondary: "#AF1685",
    logo: "/bg-city.jpg",
    checking: defaultChecking,
    savings: defaultSavings,
  },
  carrefourBanque: {
    id: "carrefour_banque",
    name: "Carrefour Banque",
    primary: "#0970E6",
    secondary: "#64C2C8",
    logo: "/bg-city.jpg",
    checking: {
      name: "Carte PASS",
      description: "Credit Card",
      image: "/img_carte_standard_tableau.webp",
    },
    savings: {
      name: "Carte PASS Premium",
      description: "Credit Card",
      image: "/img_carte_gold_tableau.webp",
    },
  },
  entrustUniversity: {
    id: "entrust_university",
    name: "Entrust University",
    primary: "#6d2077",
    secondary: "#af1685",
    logo: "/bg-city.jpg",
    checking: defaultChecking,
    savings: defaultSavings,
  },
  wellsFargo: {
    id: "well_fargo",
    name: "Wells Fargo Bank",
    primary: "#d71e28",
    secondary: "#ffcd41",
    logo: "/wells-fargo.jpeg",
    checking: defaultChecking,
    savings: defaultSavings,
  },
};

export const getThemeKey = (name: string) => {
  const index = Object.values(themes).findIndex((t) => t.name === name);
  return Object.keys(themes)[index];
};
