import { type CARD_TYPE, userStore } from "../stores/UserStore";
import { capitalize } from "./formatter";

export const getCardImage = (cardType: CARD_TYPE): string => {
  if (cardType === "checking") {
    return userStore.theme.checking.image;
  }

  if (cardType === "savings") {
    return userStore.theme.savings.image;
  }

  return "";
};

export const getCardName = (cardType: CARD_TYPE): string => {
  if (cardType === "checking") {
    return userStore.theme.checking.name;
  }

  if (cardType === "savings") {
    return userStore.theme.savings.name;
  }

  return capitalize(cardType);
};

export const getCardType = (cardType: CARD_TYPE): string => {
  if (cardType === "checking") {
    return userStore.theme.checking.name;
  }

  if (cardType === "savings") {
    return userStore.theme.savings.name;
  }

  return "";
};

export const getRandomCardNumber = (): string => {
  let cardNumber = "XXXXX-XX-XXXXX";
  do {
    const rnd = getRandomNumber();
    cardNumber = cardNumber.replace("X", rnd.toString());
  } while (cardNumber.includes("X"));
  return cardNumber;
};

export const getRandomNumber = (min = 0, max = 9): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomPin = (): string => {
  const num = Math.floor(Math.random() * 10000);

  return num.toString().padStart(4, "0");
};
