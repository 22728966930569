import { type Theme as MuiTheme, createTheme } from "@mui/material";
import { startRegistration } from "@simplewebauthn/browser";
import type { PublicKeyCredentialCreationOptionsJSON } from "@simplewebauthn/types";
import { proxy, subscribe } from "valtio";
import { getRandomCardNumber, getRandomNumber, getRandomPin } from "../lib/card";
import { type Theme, type ThemeId, themes } from "../theme";
import { errorHandler } from "./MessageStore";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    monospace: true;
  }
}

export type CARD_TYPE = "checking" | "savings";
export type BIOMETRIC_PLATFORM = "web" | "mobile";
export type AUTHENTICATION_FLOW = "redirect" | "popup";

const LOCAL_STORAGE_KEY = "bank_poc";

const { VITE_FULL_WORKFLOW_ID } = import.meta.env;

export interface FIDOToken {
  allowedActions?: Array<"DELETE" | "ENABLE" | "DISABLE" | "RENAME">;
  createDate?: string;
  id?: string;
  lastUsedDate?: string;
  name?: string;
  origin?: string;
  relyingPartyId?: string;
  state?: "ACTIVE" | "INACTIVE";
  userId?: string;
  userIdStored?: boolean;
  userUUID?: string;
}

interface Token {
  id: string;
  platform: string;
  serialNumber: string;
  state: string;
  type: string;
}

export interface UserProfile {
  firstName: string;
  lastName: string;
  userId: string;
  email: string;
  tokens: Token[];
}

export interface VirtualCard {
  nickname: string;
  cardNumber: string;
  numOfDays: number;
  limit: number;
}

export interface Account {
  type: CARD_TYPE;
  balance: number;
  number: string;
  pin: string;
  virtualCards: VirtualCard[];
}

export interface WebhookFIDORegisterChallenge {
  challenge: string;
  registration_authenticator_attachment: "EITHER" | "PLATFORM" | "CROSS_PLATFORM";
  registration_require_resident_key: "DISCOURAGED" | "PREFERRED" | "REQUIRED";
  registration_user_verification: "DISCOURAGED" | "PREFERRED" | "REQUIRED";
  rp_name: string;
  user_display_name: string;
  registered_credentials: string;
  email_address: string;
  user_id: string;
}

export interface UserProviderStore {
  accounts: Account[];
  user?: UserProfile;
  enrollmentId?: string;
  isUniversityTheme: () => boolean;
  documentMediaIds?: DocumentMediaIds[];
  applicantId?: string;

  // App Settings
  registrationWorkflowId: string;
  biometricStepUp: BIOMETRIC_PLATFORM;
  authenticationFlow: AUTHENTICATION_FLOW;
  themeId: ThemeId;
  theme: Theme;
  setTheme: (name: string) => void;
  loadTheme: () => MuiTheme;
  resetSettings: () => void;

  // Account Management
  addAccount: (type: CARD_TYPE, randomBalance?: boolean) => void;
  addVirtualCard: (nickname: string, numOfDays: number, limit: number, cardNumber: string, type: CARD_TYPE) => void;
  removeVirtualCard: (virtualCard: VirtualCard, type: CARD_TYPE) => void;
  updateEmail: (newEmail: string) => void;
  resetAccounts: () => void;
  changePin: (pin: string, type: CARD_TYPE) => void;
  sendFunds: (from: CARD_TYPE, amount: number) => void;
  transferFunds: (from: CARD_TYPE, to: CARD_TYPE, amount: number) => void;
  fundAccount: (account: CARD_TYPE, amount: number) => void;
  fetchProfileInfo: (userId: string) => Promise<void>;
  fetchTokens: (userId: string) => Promise<Token[]>;

  // IIDaaS Values
  setEnrollmentId: (enrollmentId: string | undefined) => void;
  setDocumentMediaIds: (mediaIds: DocumentMediaIds[] | undefined) => void;
  setApplicantId: (applicantId: string | undefined) => void;

  // IDaaS Registration
  completeFidoRegistration: (fidoRegisterChallenge: WebhookFIDORegisterChallenge) => Promise<FIDOToken | undefined>;

  // Session Management
  logout: () => void;
  isLoggedIn: () => boolean;
  hasActiveToken: (tokens?: Token[]) => boolean;
}

const initialStateRaw = localStorage.getItem(LOCAL_STORAGE_KEY) ?? "{}";
const initialState = JSON.parse(initialStateRaw) as {
  accounts?: Account[];
  user?: UserProfile;
  isUniversityTheme?: () => boolean;
};

// special handling to fix a bug Andrew made which causes the UI to crash
// from commit 9296e8459dc7b939363f30b4f7fe5c5467460ff0
if (initialState.isUniversityTheme !== undefined) {
  // biome-ignore lint/performance/noDelete: setting to undefined does not work
  delete initialState.isUniversityTheme;
}

export const userStore: UserProviderStore = proxy<UserProviderStore>({
  accounts: [],
  user: undefined,
  registrationWorkflowId: VITE_FULL_WORKFLOW_ID,
  biometricStepUp: "web",
  authenticationFlow: "redirect",
  themeId: "bank_of_entrust",
  theme: themes.bankOfEntrust,
  enrollmentId: "",
  isUniversityTheme: () => {
    return userStore.theme.id === "entrust_university";
  },
  documentMediaIds: undefined,
  applicantId: undefined,
  setTheme: (themeKey: string) => {
    if (themes[themeKey]) {
      const theme = themes[themeKey];
      userStore.theme = theme;
      userStore.themeId = theme.id;
    }
  },
  loadTheme: () => {
    const { themeId } = userStore;

    const theme = Object.values(themes).find((theme) => theme.id === themeId) ?? themes.bankOfEntrust;

    userStore.theme = theme;

    // Set the tab title
    document.title = theme.name;

    return createTheme({
      palette: {
        primary: {
          main: theme.primary,
        },
        secondary: {
          main: theme.secondary,
        },
      },
      shape: {
        borderRadius: 8,
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              monospace: "code",
            },
          },
        },
      },
    });
  },

  resetSettings: () => {
    localStorage.clear();
    window.location.pathname = "/";
  },

  addAccount: (type, randomBalance = false) => {
    const balance = randomBalance ? getRandomNumber(1000, 10000) : 0;
    const number = getRandomCardNumber();
    const pin = getRandomPin();
    userStore.accounts.push({ type, balance, number, pin, virtualCards: [] });
  },
  addVirtualCard: (nickname, numOfDays, limit, cardNumber, type) => {
    const index = userStore.accounts.findIndex((a) => a.type === type);
    if (!userStore.accounts[index].virtualCards) {
      userStore.accounts[index].virtualCards = [];
    }
    userStore.accounts[index].virtualCards.push({ nickname, numOfDays, limit, cardNumber });
  },
  removeVirtualCard: (virtualCard, type) => {
    const index = userStore.accounts.findIndex((a) => a.type === type);
    const cardIndex = userStore.accounts[index].virtualCards.findIndex((a) => a.nickname === virtualCard.nickname);

    if (cardIndex > -1) {
      userStore.accounts[index].virtualCards.splice(cardIndex, 1);
    }
  },
  updateEmail: (email: string) => {
    if (userStore.user && email) {
      userStore.user.email = email;
    }
  },
  resetAccounts: () => {
    userStore.accounts = [];
  },
  transferFunds: (from: CARD_TYPE, to: CARD_TYPE, amount: number) => {
    const fromIndex = userStore.accounts.findIndex((a) => a.type === from);
    const toIndex = userStore.accounts.findIndex((a) => a.type === to);

    const fromBalance = userStore.accounts[fromIndex].balance;
    const toBalance = userStore.accounts[toIndex].balance;

    userStore.accounts[fromIndex].balance = fromBalance - amount;
    userStore.accounts[toIndex].balance = toBalance + amount;
  },
  fundAccount: (account: CARD_TYPE, amount: number) => {
    const index = userStore.accounts.findIndex((a) => a.type === account);

    const balance = userStore.accounts[index].balance;

    userStore.accounts[index].balance = balance + amount;
  },
  fetchProfileInfo: async (userId: string) => {
    const response = await fetch(`/api/user/${userId}`);

    const data = await response.json();

    userStore.user = {
      firstName: data.firstName ?? "",
      lastName: data.lastName ?? "",
      userId: data.userId ?? "",
      email: data.userId ?? "",
      tokens: data.tokens ?? [],
    };
  },
  fetchTokens: async (userId: string) => {
    const response = await fetch(`/api/user/${userId}`);

    const data = await response.json();

    return data.tokens ?? [];
  },
  changePin: (pin: string, type: CARD_TYPE) => {
    const index = userStore.accounts.findIndex((a) => a.type === type);
    userStore.accounts[index].pin = pin;
  },
  sendFunds: (from: CARD_TYPE, amount: number) => {
    const fromIndex = userStore.accounts.findIndex((a) => a.type === from);

    const fromBalance = userStore.accounts[fromIndex].balance;

    userStore.accounts[fromIndex].balance = fromBalance - amount;
  },
  setEnrollmentId: (enrollmentId) => {
    userStore.enrollmentId = enrollmentId;
  },
  setDocumentMediaIds(mediaIds) {
    userStore.documentMediaIds = mediaIds;
  },
  setApplicantId(applicantId) {
    userStore.applicantId = applicantId;
  },
  completeFidoRegistration: async (fidoRegisterChallenge: WebhookFIDORegisterChallenge) => {
    let registeringTokenNum = 1;
    if (fidoRegisterChallenge.registered_credentials) {
      registeringTokenNum = fidoRegisterChallenge.registered_credentials?.split(",").length + 1;
    }

    const tokenCreationOptions: PublicKeyCredentialCreationOptionsJSON = {
      challenge: fidoRegisterChallenge.challenge ?? "",
      rp: {
        name: fidoRegisterChallenge.rp_name ?? "",
      },
      user: {
        displayName: fidoRegisterChallenge.user_display_name ?? "",
        name: fidoRegisterChallenge.email_address,
        id: fidoRegisterChallenge.user_id ?? "",
      },
      pubKeyCredParams: [
        {
          alg: -7, // ES256
          type: "public-key",
        },
        {
          alg: -257, // RS256
          type: "public-key",
        },
      ],
      attestation: "none",
      timeout: 30 * 1000, // 30 seconds
      authenticatorSelection: {
        requireResidentKey: true,
        residentKey: "required",
        userVerification: "required",
      },
    };

    try {
      const registrationResponseJson = await startRegistration({ optionsJSON: tokenCreationOptions });

      const body: CompletePasskeyRegistration = {
        attestationObject: registrationResponseJson.response.attestationObject,
        clientDataJSON: registrationResponseJson.response.clientDataJSON,
        name: `${userStore.theme.name} ${registeringTokenNum}`,
        userIdStored: true,
        id: atob(fidoRegisterChallenge.user_id ?? ""),
      };

      const response = await fetch("/api/onboarding/passkey", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(body),
      });

      const tokenResponse = (await response.json()) as FIDOToken;

      return tokenResponse;
    } catch (error) {
      console.error("Failed to complete FIDO registration.", error);
      if (error instanceof Error) {
        errorHandler(error);
      }
    }
  },

  logout: () => {
    userStore.user = undefined;
  },
  isLoggedIn: () => {
    return !!userStore.user;
  },
  hasActiveToken: (tokens?: Token[]) => {
    const t = tokens ?? userStore.user?.tokens ?? [];

    return t.some((t) => t.state === "ACTIVE") ?? false;
  },
  ...initialState,
});

subscribe(userStore, () => {
  const { theme, ...store } = userStore;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(store));
  console.log("⚡ Store changed", JSON.stringify(userStore));
});
